<script>
export default {
	inject: ['manager'],
	props: {
		tagClass: String,
	},
}
</script>

<template>
	<div
		v-if="manager.tags && manager.tags.length"
		:class="[
			'product-card__tags d-flex flex-column',
			{
				'product-card__tags--desktop': !$b.m,
				'align-baseline': manager.alignment == 'left',
				'align-center': manager.alignment == 'center',
				'align-end': manager.alignment == 'right',
			},
		]"
		style="gap: 6px"
	>
		<div
			v-for="({ html, style }, n) in manager.tags.splice(0, 2)"
			:key="n"
			class="product-card__tag rounded line-clamp-1 px-1"
			v-html="html"
			:class="tagClass"
			:style="style"
		></div>
	</div>
</template>
